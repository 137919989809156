import { Link } from 'gatsby'
import React from 'react'
import { buildImageObj, cn, getBlogUrl } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import BlockText from '../block-text'
import MdWatch from 'react-ionicons/lib/MdWatch'
import { format } from 'date-fns'
import IosArrowForward from 'react-ionicons/lib/IosArrowForward'

// reactstrap components
import {
  Button,
  Card,
  CardImg,
  CardHeader,
  CardBody,
  CardFooter,
  CardSubtitle,
  CardTitle,
  CardText,
  ListGroupItem,
  ListGroup,
  Container,
  Row,
  Col
} from "reactstrap";

const tagName = [
  'text-warning',
  'text-info',
  'text-primary',
  'text-danger',
  'text-success'
];

if (!Array.prototype.getRandomIndex) {
  Array.prototype.getRandomIndex = function() {
    return this[Math.floor(Math.random() * this.length)];
  }
}

function BlogPostPreviewHorizonal (props) {
	const previewDateSegment = format(props.publishedAt, 'MMM DD')
  const dateSegment = props.noDate ? "" : format(props.publishedAt, 'YYYY/MM') + "/";
	
  return (
    <Link to={ props.slug && props.slug.current ? `/${props.postType || 'blog'}/${dateSegment}${props.slug.current}/` : props.link}>
      {props.mainImage && props.mainImage.asset && (
        <Card className={`card-blog card-plain blog-horizontal`}>
        <Row>
          <Col lg={"4"}>
            <div 
              className={'card-img'} 
              style={{
                maxHeight: 380, 
                minHeight: 380,
                backgroundImage: `url(${(/https\:\/\/|http\:\/\//g).test(props.mainImage.asset) ? props.mainImage.asset : imageUrlFor(buildImageObj(props.mainImage)).url()})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
              }}
            />
          </Col>
          <Col lg={"8"}>
            <CardBody className={"content-center"}>
                <CardHeader>
                    {
                      props.icon && (
                        <props.icon fontSize={"45px"}/>
                      )
                    }
                    { 
                      props._rawCategories && ( props._rawCategories.map( category => (
                        <div 
                          key={Math.random(10)}
                          className={`card-category ${tagName.getRandomIndex()}`}
                          style={{
                            textTransform: 'uppercase',
                            fontWeight: '400',
                            fontSize: '.75rem',
                            lineHeight: '1.2rem',
                            marginBottom: '.1rem',
                            marginTop: '.1rem',
                            color: "#ffffff"
                          }}>
                          {category.title}
                        </div>
                      )) )
                    } 
                </CardHeader>
                  <h3 className={"card-title"}>
                    <Link to={ props.slug && props.slug.current ? `/${props.postType || 'blog'}/${dateSegment}/${props.slug.current}/` : props.link}>
                      {props.title}
                    </Link>
                  </h3>
                  { props._rawExcerpt && (
                    <>
                    <BlockText blocks={props._rawExcerpt} classOverride={'card-description'}/>
                    </>
                  )}
                  <Row>
                    {
                      props.authors && (
                        <Col sm={"5"}>
                          <div className="author">
                              {
                                props.authors[0].person.image &&
                                (<img src={imageUrlFor(buildImageObj(props.authors[0].person.image)).url()} alt={props.authors[0].person.name} class="avatar img-raised" />)
                              }
                              <div className={"meta mr-auto ml-3"}>
                                {
                                  props.authors[0].person.name && (
                                  <div className={'name'}>{props.authors[0].person.name}</div>
                                  )
                                }
                                {
                                  dateSegment && (
                                  <div className={'text'}>Wrote on: {previewDateSegment}</div>
                                  )
                                }
                              </div>
                          </div>
                        </Col>
                      )
                    }
                    <Col sm={"12"} md={"4"} className={'md-ml-auto'} style={{paddingTop: 15, paddingBottom: 30}}>
                      <Link to={ props.slug && props.slug.current ? `/${props.postType || 'blog'}/${dateSegment}/${props.slug.current}/` : props.link}>
                        <Button
                          className="btn btn-success btn-round btn-simple"
                          color="success"
                          type="button"
                          >
                            Read more <IosArrowForward/>
                        </Button>
                      </Link>
                    </Col>
                  </Row>
            </CardBody>
          </Col>
        </Row>
        </Card>
      )}
    </Link>
  )
}

export default BlogPostPreviewHorizonal
